
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { ExtendedProfile, PianoCustomerProfile } from '@piano/types/pianoState';

interface Props {
  data: {
    isLoggedIn: boolean;
    profile: PianoCustomerProfile | null;
    extendedProfile: ExtendedProfile | null;
  };
  openLoginModal: () => void;
  logout: () => void;
}

interface Computed {
  title: string;
  hasChannelSubscription: boolean;
  subscription: Record<string, any> | false;
  isLoggedIn: boolean;
  list: {
    class?: string;
    type?: string;
    content?: {
      class?: string;
      target?: string;
      href: string | TranslateResult;
      icon?: string[];
      text: string | TranslateResult;
    };
    showForLoggedIn?: boolean;
    disabled?: boolean;
  }[];
  hideOrdering: boolean;
}

interface Methods {
  handleLogin: () => void;
  handleLogout: () => void;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  props: {
    data: {
      type: Object,
      required: true,
    },
    openLoginModal: {
      type: Function,
      required: true,
    },
    logout: {
      type: Function,
      required: true,
    },
  },
  computed: {
    list() {
      const isTest = typeof window === 'undefined' ? process.env.CHANNEL_CONFIG_ENV === 'test' : /.test.delfi/.test(document.location.hostname);
      const list = [
        {
          type: 'profile',
          content: {
            href: isTest ? (this.$t('login.routes.profile') as string).replace('.delfi', '.test.delfi') : this.$t('login.routes.profile'),
            icon: ['user-circle'],
            target: '_self',
            text: this.$t('login.profile'),
          },
          showForLoggedIn: true,
          disabled: false,
        },
        {
          type: 'subscriptions',
          content: {
            href: isTest ? (this.$t('login.routes.subscriptions') as string).replace('.delfi', '.test.delfi') : this.$t('login.routes.subscriptions'),
            target: '_self',
            text: this.$t('login.subscriptions'),
          },
          showForLoggedIn: true,
          disabled: false,
        },
        {
          type: 'bookmarks',
          content: {
            href: isTest ? (this.$t('login.routes.read_later') as string).replace('.delfi', '.test.delfi') : this.$t('login.routes.read_later'),
            icon: ['bookmark'],
            text: this.$t('login.read_later'),
          },
          showForLoggedIn: true,
          disabled: false,
        },
        {
          type: 'history',
          content: {
            href: isTest ? (this.$t('login.routes.history') as string).replace('.delfi', '.test.delfi') : this.$t('login.routes.history'),
            icon: ['history'],
            text: this.$t('login.history'),
          },
          showForLoggedIn: true,
          disabled: false,
        },
        {
          type: 'myDelfi',
          content: {
            href: isTest ? (this.$t('login.routes.my_delfi') as string).replace('.delfi', '.test.delfi') : this.$t('login.routes.my_delfi'),
            icon: [],
            text: this.$t('login.my_delfi'),
          },
          showForLoggedIn: true,
          disabled: false,
        },
        {
          class: 'header-customer-menu__item-content--separator display-none display-lg-block',
        },
      ];

      for (const item of list) {
        if (!item.disabled && item.showForLoggedIn) {
          item.disabled = !this.isLoggedIn;
        }
      }

      return list;
    },
    title() {
      return `${this.$t('login.hello')}`;
    },
    hasChannelSubscription() {
      return this.$store.state.piano.access.channelAccess;
    },
    subscription() {
      if (!this.hasChannelSubscription) {
        return false;
      }

      return this.$store.state.piano.subscriptionInfo;
    },
    isLoggedIn() {
      return this.data.isLoggedIn;
    },
    hideOrdering() {
      const { specialFeatures } = this.$channelConfig('settings').application;
      return specialFeatures.hideSignupAndOrders.enabled;
    },
  },
  methods: {
    handleLogin() {
      this.$store.commit('navigation/setHeaderAction', { type: 'profileInfo', value: false });
      this.openLoginModal();
    },
    handleLogout() {
      this.$store.commit('navigation/setHeaderAction', { type: 'profileInfo', value: false });
      this.logout();
    },
  },
});
