import Vue from 'vue';
import { AudioItem, PlayerPlugin } from '../types/player';
import playerConfig from '../config/player.config';
import { getLastPlayed } from './storage';

export class PlayerState {
  state: PlayerPlugin['state'];
  constructor() {
    this.state = this.getDefaultState();
  }
  private getDefaultState(): PlayerPlugin['state'] {
    return {
      currentlyPlaying: {
        file: '',
        audioDuration: 0,
        audioId: '',
        audioPosition: 0,
        audioListeningPercentage: 0,
        description: '',
        descriptionLink: '',
        assetType: 'podcasts',
        img: {},
        title: '',
        articleId: 0,
        parentTitle: '',
        categoryTitle: '',
        playbackRate: 1,
        autoPlay: true,
        parentId: '',
        author: '',
      },
      isPlaying: false,
      isAdPlaying: false,
      showBigPlayer: false,
      playlist: [],
      lastPlayed: getLastPlayed() || null,
    };
  }

  setState(audioItem: AudioItem): void {
    const parsedAudioId = String(audioItem.audioId);

    const audioDuration = audioItem.audioDuration || 0;
    const audioPosition = audioItem.audioPosition || 0;

    this.state.currentlyPlaying = {
      file: audioItem.file,
      audioDuration,
      audioId: parsedAudioId,
      audioPosition,
      audioListeningPercentage: (audioPosition / audioDuration) * 100,
      description: audioItem.description,
      descriptionLink: audioItem.descriptionLink || '',
      assetType: audioItem.assetType,
      img: audioItem.img,
      title: audioItem.title,
      articleId: audioItem.articleId || 0,
      parentTitle: audioItem.parentTitle || '',
      categoryTitle: audioItem.categoryTitle || '',
      playbackRate: this.state.currentlyPlaying.playbackRate,
      autoPlay: audioItem.autoPlay || false,
      parentId: audioItem.parentId || '',
      author: audioItem.author || '',
    };
  }
  remove(): void {
    const defaultState = this.getDefaultState();
    for (const [key, value] of Object.entries(defaultState)) {
      Vue.set(this.state, key, value);
    }
  }
  play(): void {
    this.state.isPlaying = true;
  }
  getSeekBackPosition() {
    const audioPosition = this.state.currentlyPlaying.audioPosition;

    return Math.max(audioPosition + playerConfig.seekBackInterval, 0);
  }
  getSeekForwardPosition() {
    const audioPosition = this.state.currentlyPlaying.audioPosition;

    return audioPosition + playerConfig.seekForwardInterval;
  }
  pause(): void {
    this.state.isPlaying = false;
  }
  changeTitle(title: string): void {
    this.state.currentlyPlaying.title = title;
  }
  setPlaybackRate(rate: number): void {
    this.state.currentlyPlaying.playbackRate = rate;
  }
  toggleBigPlayer() {
    this.state.showBigPlayer = !this.state.showBigPlayer;
  }
}
