import Service from '@root/common/base/Service';
import { ServiceInterface } from '@root/common/types/service';

import getAudio from '@root/modules/podcast/graphql/media/getAudio.graphql';

type ServiceVariables = {
  id: string[];
  getPlaylistLocation: boolean;
};

interface AudioItem {
  id: string;
  type: 'AUDIO';
  metadata: {
    title: string | null;
    credit: string | null;
    description: string | null;
    authors: string[] | null;
    tags?: string[] | null;
    adsDisabled: boolean | null;
    audioPreviewImage: {
      id: string | null;
    };
  };
  audioData?: {
    audioPlaylistLocation?: string;
    audioDuration?: number;
  };
}

interface Response {
  variables: ServiceVariables;
  audio: {
    items: AudioItem[];
  };
}

export default class MediaApiService extends Service implements ServiceInterface {
  public async fetch(variables: ServiceVariables) {
    try {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('media-api').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

      const options = Object.assign({ query: getAudio }, { variables });
      const response = await apiProvider.query<Response>(options);

      return response.data?.audio?.items;
    } catch (e) {
      const error = this.generateErrorData(e);
      this.handleError(error);
    }
  }
}
