import { ActionTree, GetterTree } from 'vuex';
import { Podcast, PlaylistType } from '@root/modules/podcast/types/podcast';
import { CustomerData } from '@root/modules/customer/types/customer';

import CustomerDataService from '@root/modules/customer/services/CustomerData.service';
import PodcastEpisodeService from '@root/modules/podcast/services/podcastEpisode.service';
import { getPlaylist, savePlaylist, savePlaylistType } from '../utils/storage';

interface State {
  customerToken: string | null;
  customerPlaylist: Podcast[] | null;
  playlistData: CustomerData[];
  playlist: Podcast[] | null;
  activePlaylistType: PlaylistType;
  showPaywall: boolean;
}

const getCustomerPlaylist = async (customerToken: string) => {
  const customerDataService = new CustomerDataService();
  customerDataService.setCustomerToken(customerToken);
  const response = await customerDataService?.get({ key: ['podcast-bookmark'] });
  const playlistData = response?.data?.items || [];

  let customerPlaylist: Podcast[] = [];
  if (playlistData.length) {
    const podcastEpisodeService = new PodcastEpisodeService();
    const episodes = (await podcastEpisodeService.fetch({ id: playlistData.map((dataItem) => Number(dataItem?.value)) })) || [];
    if (episodes.length) {
      customerPlaylist = episodes;
    }
  }

  return {
    playlistData,
    customerPlaylist,
  };
};

export const state: () => State = () => ({
  customerToken: null,
  customerPlaylist: null,
  playlistData: [],
  playlist: null,
  activePlaylistType: 'podcast',
  showPaywall: false,
});

export const getters: GetterTree<State, Record<string, unknown>> = {
  getPlaylist: (state) => state.playlist,
  getCustomerPlaylist: (state) => state.customerPlaylist,
  getActivePlaylist: (state) => {
    if (state.activePlaylistType === 'customer') {
      return state.customerPlaylist || getPlaylist('customer');
    }

    return state.playlist || getPlaylist('podcast');
  },
  getActivePlaylistType: (state) => state.activePlaylistType,
};

export const actions: ActionTree<State, any> = {
  async setCustomerToken({ state }, token: string) {
    state.customerToken = token;
  },
  async setCustomerPlaylist({ state }) {
    if (!state.customerToken) {
      return;
    }

    const { playlistData, customerPlaylist } = await getCustomerPlaylist(state.customerToken);

    savePlaylist(customerPlaylist, 'customer');

    state.playlistData = playlistData;
    state.customerPlaylist = customerPlaylist;
  },
  async addToPlaylist({ state }, id: string) {
    if (!state.customerToken) {
      return;
    }

    const customerDataService = new CustomerDataService();
    customerDataService.setCustomerToken(state.customerToken);
    await customerDataService.create({ key: 'podcast-bookmark', value: `${id}` });

    const { playlistData, customerPlaylist } = await getCustomerPlaylist(state.customerToken);
    savePlaylist(customerPlaylist, 'customer');

    state.playlistData = playlistData;
    state.customerPlaylist = customerPlaylist;
  },
  async removeFromPlaylist({ state }, id: string) {
    if (!state.customerToken) {
      return;
    }

    const playlistDataItem = state.playlistData.find((dataItem) => dataItem.value === String(id));
    if (playlistDataItem) {
      const customerDataService = new CustomerDataService();
      customerDataService.setCustomerToken(state.customerToken);
      await customerDataService.delete({ id: playlistDataItem.id });

      const { playlistData, customerPlaylist } = await getCustomerPlaylist(state.customerToken);
      savePlaylist(customerPlaylist, 'customer');

      state.playlistData = playlistData;
      state.customerPlaylist = customerPlaylist;
    }
  },
  setPlaylist({ state }, playlist: Podcast[]) {
    savePlaylist(playlist);

    state.playlist = playlist;
  },
  setActivePlaylistType({ state }, activePlaylistType: PlaylistType) {
    savePlaylistType(activePlaylistType);

    state.activePlaylistType = activePlaylistType;
  },
  setShowPaywall({ state }, value: boolean) {
    state.showPaywall = value;
  },
};
