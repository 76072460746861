import channels, { Channel } from '@root/modules/channel/config/channel.config';
import rootConfig from '@root/config/root.config';
import matchChannel from '@root/common/utils/matchChannel';
import { Data as HeadlinesData } from '@root/modules/headlines/types/headlines';
import { Data as ArticleData } from '@root/modules/article/types/article';
import { buildQueryUrl } from '@root/router/utils/buildQueryUrl';
import { Context } from '@nuxt/types';

type BuildPodcastUrl = ({
  article,
  domain,
  fakeDomainEnabled,
  articlesLinksHash,
}: {
  article: HeadlinesData | ArticleData;
  domain?: string | string[];
  fakeDomainEnabled?: boolean;
  articlesLinksHash?: string;
  query?: Context['route']['query'];
  route?: Context['route'];
}) => string;

function generatePath(article: HeadlinesData | ArticleData) {
  const items = [];

  const channel = article.primaryCategory?.channel;
  const channelConfig = channels.find((ch) => ch.url === channel.url) || null;

  const channelPath = (channel && channel.path) || rootConfig.main_slug;
  if (channelPath !== null) {
    items.push(channelPath);
  }

  // FIXME: could be channelConfig.external_id int to get rid of this .toString()?
  const parentCategory = article.primaryCategory.parentCategory || null;
  if (parentCategory && parentCategory.id && parentCategory.id.toString() !== channelConfig?.external_id) {
    items.push(parentCategory.slug);
  }

  const categorySlug = article.primaryCategory.slug;
  items.push(categorySlug);

  return items.join('/');
}

function buildPodcastDevUrl(article: HeadlinesData | ArticleData) {
  const origin = process.client ? document.location.origin : '';
  const path = generatePath(article);
  return `${origin}/${path ? path + '/' : ''}/${article.slug}-${article.id}`;
}

function buildPodcastLiveUrl(article: HeadlinesData | ArticleData, channelConfig: Channel) {
  const path = generatePath(article);
  const domain = channelConfig.domain;
  return `https://${domain}/${path ? path + '/' : ''}/${article.slug}-${article.id}`;
}

const buildPodcastUrl: BuildPodcastUrl = ({ article, domain, fakeDomainEnabled, articlesLinksHash, query, route }) => {
  const channel = article.primaryCategory?.channel;

  if (!channel) {
    return '';
  }

  const isDev = fakeDomainEnabled;
  const path = generatePath(article);

  let articleUrl = `/${path}/${article.slug}-${article.id}`;

  const sameChannel = domain ? matchChannel(domain, channel.url) : true;
  if (!sameChannel) {
    // TODO: Change after Content-api channel object will return channel url
    const channelConfig = channels.find((ch) => ch.url === channel.url) || null;

    if (channelConfig) {
      articleUrl = isDev ? buildPodcastDevUrl(article) : buildPodcastLiveUrl(article, channelConfig);
    } else {
      articleUrl = `${rootConfig.main_portal_url}/a/${article.id}`;
    }
  }

  if (query) {
    const queryUrl = buildQueryUrl(articleUrl, { query, route });
    articleUrl = queryUrl;
  }

  return `${articleUrl}${articlesLinksHash || ''}`;
};

export default buildPodcastUrl;
