interface CropperData {
  w?: number;
  h?: number;
  r?: string;
}

type GetCropperUrlArguments = {
  id: string;
  cropperUrl: string;
  cropperData?: CropperData;
};

const getCropperUrl = ({ id, cropperUrl, cropperData = {} }: GetCropperUrlArguments) => {
  if (!id || !cropperUrl) {
    return '';
  }

  const url = new URL(`${id}.jpg`, `${cropperUrl}/`);

  for (const [key, value] of Object.entries(cropperData)) {
    url.searchParams.set(key, value);
  }

  return url.href;
};

export default getCropperUrl;
