import { QueryOptions } from '@apollo/client/core';
import BaseService from '@root/common/base/BaseService';
import { ServiceInterface } from '@root/common/types/service';
import getPodcastsEpisodes from '@root/modules/podcast/graphql/getPodcastsEpisodes.graphql';
import { dataLoader, RedisDataLoaderOptions } from '@root/libs/redis/dataloader/dataLoader';
import { Podcast } from '@root/modules/podcast/types/podcast';

interface ServiceVariables {
  id?: number[];
  categoryId?: number[];
  search?: {
    field: string[];
    value: string;
  };
  orderBy?: string;
  orderDirection?: string;
  offset?: number;
  limit?: number;
}

interface Response {
  getArticles: {
    items: Podcast[];
  };
}

export default class PodcastEpisodeService extends BaseService implements ServiceInterface {
  private _customerToken: string | null = null;

  public setCustomerToken(customerToken: string) {
    this._customerToken = customerToken;
  }

  private async fetchEpisodes(variables: ServiceVariables) {
    const headers = this._customerToken ? { Authorization: `Bearer ${this._customerToken}` } : {};
    const options: QueryOptions = Object.assign({ query: getPodcastsEpisodes }, { variables, context: { headers } });
    const dataLoaderOptions: RedisDataLoaderOptions = {
      remote: {
        expireTimeMs: 30000, // 30 seconds
        gracePeriodMs: 24 * 3600 * 1000, // 24 hours
        keyPrefix: 'page_feed',
      },
    };

    const requestWrapper = async (options: QueryOptions): Promise<Response | Error> => {
      const apiProvider = this.createProvider('GraphQL');
      apiProvider.selectAPI('content-api-v3').setLinkOptions({ useAutomaticPersistedQueries: true, useGETAutomaticPersistedQueries: true });

      const response = await apiProvider.query<Response>(options);

      this.throwGraphqlOrApolloErrorIfExists(response);

      return response.data;
    };

    const redisDataLoader = dataLoader<QueryOptions, Response | Error>(requestWrapper, dataLoaderOptions);
    const response: Response | Error = redisDataLoader ? await redisDataLoader.load(options) : await requestWrapper(options);

    if (response instanceof Error) {
      throw response;
    }

    return response.getArticles.items;
  }

  public async fetch(variables: ServiceVariables) {
    try {
      const response = await this.fetchEpisodes(variables);

      return response;
    } catch (error) {
      process.sentry?.captureException(`PodcastEpisodeService fetch failed`, {
        contexts: { ...(error.message && { message: error.message }), ...(error.cause && { cause: error.cause }) },
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
      this.handleError({ statusCode: 500, message: 'error.unexpected_system_error' });
    }
  }
}
