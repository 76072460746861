import { MutationTree, GetterTree, ActionTree } from 'vuex';
import { State } from '~/src/modules/customer/types/BookmarkStore';
import CustomerDataService from '@root/modules/customer/services/CustomerData.service';
import { CustomerData } from '@root/modules/customer/types/customer';

export const state: () => State = () => ({
  bookmarks: [],
});

export const getters: GetterTree<State, Record<string, unknown>> = {
  getBookmarks: (state) => {
    return state.bookmarks;
  },
};

export const mutations: MutationTree<State> = {
  setBookmarks(state, bookmarks) {
    state.bookmarks = [...new Set([...state.bookmarks, ...bookmarks])];
  },
  deleteBookmark(state, bookmarkId) {
    const bookmarkedItemIndex = state.bookmarks.findIndex((bookmarkItem) => bookmarkItem.id === bookmarkId);

    if (bookmarkedItemIndex > -1) {
      state.bookmarks.splice(bookmarkedItemIndex, 1);
    }
  },
  addBookmark(state, bookmarkCreateResponseData) {
    state.bookmarks.push(bookmarkCreateResponseData);
  },
};

export const actions: ActionTree<State, Record<string, unknown>> = {
  async createBookmark({ commit }, data) {
    const { headlineId, customerToken } = data;

    try {
      const customerDataService = new CustomerDataService();
      customerDataService.setCustomerToken(customerToken);

      const bookmarkCreateResponse = await customerDataService?.create({ key: 'bookmark', value: String(headlineId) });
      if (bookmarkCreateResponse?.data.customerData) {
        commit('addBookmark', bookmarkCreateResponse.data.customerData);
      }
    } catch (e) {
      this.$sentry.captureException(e, {
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
    }
  },

  async deleteBookmark({ commit }, data) {
    const { bookmarkId, customerToken } = data;

    try {
      const customerDataService = new CustomerDataService();
      customerDataService.setCustomerToken(customerToken);
      const bookmarkDeleteResponse = await customerDataService.delete({ id: bookmarkId });

      if (bookmarkDeleteResponse?.data.status) {
        commit('deleteBookmark', bookmarkId);
      }
    } catch (e) {
      this.$sentry.captureException(e, {
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
    }
  },

  async toggleBookmark({ state, dispatch }, data) {
    const { headlineId, customerToken } = data;
    const isBookmarked = state.bookmarks.find((item: CustomerData) => item.value === String(headlineId));

    if (isBookmarked) {
      const bookmark = state.bookmarks.find((item: CustomerData) => item.value === String(headlineId));

      if (bookmark) {
        dispatch('deleteBookmark', {
          bookmarkId: bookmark.id,
          customerToken,
        });
      }
    } else if (headlineId) {
      dispatch('createBookmark', {
        headlineId,
        customerToken,
      });
    }
  },

  async loadBookmarks({ commit }, data) {
    const { customerToken } = data;

    try {
      const customerDataService = new CustomerDataService();
      customerDataService.setCustomerToken(customerToken);

      const bookmarksResponse = await customerDataService.get({
        key: ['bookmark'],
        offset: 0,
      });

      if (bookmarksResponse?.data) {
        commit('setBookmarks', bookmarksResponse.data.items);
      }
    } catch (e) {
      this.$sentry.captureException(e, {
        tags: { 'process.type': process.server ? 'server' : 'client' },
      });
    }
  },
};
