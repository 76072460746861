import { Podcast } from '@root/modules/podcast/types/podcast';
import { AssetType } from '@root/modules/podcast/types/player';

const getAssetType = (episode: Podcast): AssetType | null => {
  const isPodcastChannel = episode.primaryCategory.channel.url == 'www.delfi.lt/fone';
  const topLevelCategory = episode.primaryCategory.parentCategory?.parentCategory?.id;

  if (isPodcastChannel) {
    if (topLevelCategory === 96603920) {
      return 'podcasts';
    } else if (topLevelCategory === 96603927) {
      return 'audiobooks';
    }
  } else {
    return 'articles';
  }

  return null;
};

export default getAssetType;
