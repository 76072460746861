import { Context } from '@nuxt/types';

import channels from '../../../modules/channel/config/channel.config';

type Req = Context['req'] | PortalRootIncomingMessage;

export function getDevChannelId(req?: Req) {
  const url: string | null = req ? req.url || null : document.location.pathname;
  const path: string[] = url!.substring(1).split('/');
  const channelPath: string = path[0];

  const channelUrl = channels.find((channel) => channel.path === channelPath)?.url;
  if (channelUrl) {
    return channelUrl.replace('.test.delfi', '.delfi');
  }

  return 'www.delfi.lt';
}

export function getLiveChannelId(req?: Req) {
  const url: string | null = req ? req.url || null : document.location.pathname;
  const path: string[] = url!.substring(1).split('/');
  const channelPath: string = path[0];

  const channelUrl = channels.find((channel) => channel.path === channelPath)?.url;
  if (channelUrl) {
    return channelUrl;
  }

  return 'www.delfi.lt';
}

export function getChannelId(req?: Req) {
  const isTest = typeof window === 'undefined' ? process.env.CHANNEL_CONFIG_ENV === 'test' : /.test.delfi/.test(document.location.hostname);

  return !process.env.FAKE_DOMAIN_ENABLED || isTest ? getLiveChannelId(req) : getDevChannelId(req);
}
