import { render, staticRenderFns } from "./BaseIcon.vue?vue&type=template&id=ee9c1d1a&functional=true"
import script from "./BaseIcon.vue?vue&type=script&lang=ts"
export * from "./BaseIcon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports