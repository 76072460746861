import channels, { Channel } from '@root/modules/channel/config/channel.config';
import { Data } from '@root/modules/tags/types/tags';
import getAliasByLocale from '@root/common/utils/getAliasByLocale';

type BuildTagUrl = ({ tag, locale, domain, fakeDomainEnabled }: { tag: Data; locale: string; domain?: string; fakeDomainEnabled?: boolean }) => string;

function buildTagDevUrl(tag: Data, tagChannel: Channel) {
  const channel = channels.find((ch) => ch.external_id === tagChannel.external_id);
  const origin = process.client ? document.location.origin : '';
  let path = getAliasByLocale(tagChannel.locale, 'topics');

  if (channel!.locale === 'en_LT') {
    path = `en/${path}`;
  }

  if (channel!.locale === 'ru_LT') {
    path = `ru/${path}`;
  }

  return `${origin}/${path}/${tag.slug}`;
}

function buildTagLiveUrl(tag: Data, channelConfig: Channel) {
  const isTest = typeof window === 'undefined' ? process.env.CHANNEL_CONFIG_ENV === 'test' : /.test.delfi/.test(document.location.hostname);
  const domain = isTest ? channelConfig.domain.replace('.delfi', '.test.delfi') : channelConfig.domain;
  let path = getAliasByLocale(channelConfig.locale, 'topics');

  if (channelConfig.locale === 'en_LT') {
    path = `en/${path}`;
  }

  if (channelConfig.locale === 'ru_LT') {
    path = `ru/${path}`;
  }

  return `https://${domain}/${path}/${tag.slug}`;
}

// Build dev or live tag url
const buildTagUrl: BuildTagUrl = ({ tag, locale, domain, fakeDomainEnabled }) => {
  let path = getAliasByLocale(locale, 'topics');

  if (locale === 'en_LT') {
    path = `en/${path}`;
  }

  if (locale === 'ru_LT') {
    path = `ru/${path}`;
  }

  let tagUrl = `/${path}/${tag.slug}`;

  const isDev = fakeDomainEnabled;
  const tagChannel = channels.find((channel) => channel.tag_channel && channel.locale === locale);
  const sameChannel = tagChannel?.domain === domain;

  if (tagChannel && !sameChannel) {
    tagUrl = isDev ? buildTagDevUrl(tag, tagChannel) : buildTagLiveUrl(tag, tagChannel);
  }

  return tagUrl;
};

export default buildTagUrl;
