
import Vue from 'vue';
import CustomerDataService from '@root/modules/customer/services/CustomerData.service';
import { CustomerData, ObjectTypes } from '@root/modules/customer/types/customer';
import { Data as ArticleData } from '@root/modules/article/types/article';

interface Data {
  bookmarks: CustomerData[];
  customerDataService: CustomerDataService | null;
}

interface Computed {
  isBookmarked: boolean;
  activeArticle: ArticleData | null;
  customerToken: string;
  bookmarkedArticle: CustomerData | undefined;
}

interface Methods {
  toggleBookmark: () => Promise<void>;
  getBookmark: () => Promise<void>;
}

interface Props {
  articleId: number | null;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    articleId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      bookmarks: [],
      customerDataService: null,
    };
  },
  computed: {
    isBookmarked() {
      return !!this.bookmarkedArticle;
    },
    bookmarkedArticle() {
      return this.bookmarks.find((bookmark) => Number(bookmark.value) === this.activeArticle?.id);
    },
    activeArticle() {
      return this.$store.getters['article/getActiveArticle'];
    },
    customerToken() {
      return this.$store.state.piano.token;
    },
  },
  watch: {
    async activeArticle() {
      await this.getBookmark();
    },
    async customerToken() {
      this.customerDataService?.setCustomerToken(this.customerToken);

      if (!this.customerToken) {
        this.bookmarks = [];
      } else {
        await this.getBookmark();
      }
    },
  },
  async beforeMount() {
    this.customerDataService = new CustomerDataService();

    if (this.customerToken) {
      this.customerDataService.setCustomerToken(this.customerToken);
      await this.getBookmark();
    }
  },
  methods: {
    async toggleBookmark() {
      // If not logged in, show login modal, retry toggleBookmark() after login success
      if (!this.customerToken) {
        this.$store.dispatch('piano/showLoginModal', { screen: 'login', callback: () => setTimeout(() => this.toggleBookmark(), 500) });

        return;
      }

      if (this.isBookmarked) {
        const bookmarkedArticleIndex = this.bookmarks.indexOf(this.bookmarkedArticle!);

        try {
          this.customerDataService?.delete({ id: this.bookmarkedArticle!.id });
          this.bookmarks.splice(bookmarkedArticleIndex, 1);
        } catch (e) {
          this.$sentry.captureException(e, {
            contexts: { vue: { componentName: 'BookmarkButton', method: 'toggleBookmark delete' } },
            tags: { 'process.server': process.server ? 'server' : 'client' },
          });
        }
      } else {
        if (!this.activeArticle) {
          return;
        }

        let response;

        try {
          const key = this.activeArticle.primaryCategory.channel.url === 'www.delfi.lt/tv' ? 'video-bookmark' : 'bookmark';
          const value = this.activeArticle?.id.toString();

          response = await this.customerDataService?.create({ key, value });
        } catch (e) {
          this.$sentry.captureException(e, {
            contexts: { vue: { componentName: 'BookmarkButton', method: 'toggleBookmark create' } },
            tags: { 'process.server': process.server ? 'server' : 'client' },
          });
        }

        if (response?.data?.customerData) {
          this.bookmarks.push(response.data.customerData);
        }
      }
    },
    async getBookmark() {
      let response;

      try {
        const key: ObjectTypes[] = ['bookmark', 'video-bookmark'];
        const value = this.activeArticle?.id.toString();

        response = await this.customerDataService?.get({ key, value, limit: 1, offset: 0 });
      } catch (e) {
        this.$sentry.captureException(e, {
          contexts: { vue: { componentName: 'BookmarkButton', method: 'getBookmark' } },
          tags: { 'process.server': process.server ? 'server' : 'client' },
        });
      }

      if (response?.data?.items?.length) {
        this.bookmarks = [...new Set([...this.bookmarks, ...response.data.items])];
      }
    },
  },
});
