const calculateHorizontalAnimationDuration = (contentWidth: number, parentWidth?: number): number => {
  let desiredSpeed = 0;

  if (parentWidth) {
    desiredSpeed = contentWidth / parentWidth;
  }

  return desiredSpeed * 1000;
};

export { calculateHorizontalAnimationDuration };
