
import Vue from 'vue';
import { CustomerData } from '@root/modules/customer/types/customer';

interface Props {
  headlineId: number;
}

interface Computed {
  isBookmarked: boolean;
  customerToken: string;
  bookmarks: CustomerData[];
}

interface Methods {
  toggleBookmark: () => void;
}

export default Vue.extend<unknown, Methods, Computed, Props>({
  name: 'HeadlineBookmarks',
  props: {
    headlineId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isBookmarked() {
      const isBookmarked = this.bookmarks.find((item: CustomerData) => item.value === String(this.headlineId));
      return !!isBookmarked;
    },
    customerToken() {
      return this.$store.state.piano.token;
    },
    bookmarks() {
      return this.$store.getters['bookmarks/getBookmarks'];
    },
  },
  methods: {
    async toggleBookmark() {
      await this.$store.dispatch('bookmarks/toggleBookmark', {
        headlineId: this.headlineId,
        customerToken: this.customerToken,
      });
    },
  },
});
