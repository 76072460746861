type VastType = 'pre';
type Vast = 'default' | 'audio';

export const apiUrl = {
  mediaApiUrl: 'https://media.api.delfi.lt/media/v1/graphql',
  mediaApiImageCropperUrl: 'https://images.delfi.lt/media-api-image-cropper/v1',
};

export const jwPlayerCdnUrl = 'https://g.delfi.lt/components/video/jwplayer-8.36.4/jwplayer.js';
export const jwPlayerKey = '9DWw9dVYYxaI81olA/MQakzCZ7KSOexX2zl05F0u0dAts9Ii';

interface PlayerAdsConfug {
  vastUrl: string;
  ids: Record<Vast, Record<VastType, number>>;
}

export const playerAdsConfig: PlayerAdsConfug = {
  vastUrl: 'https://adx.adform.net/adx/?mid=%{vastId}&t=2&mkw=%{mkw}&rnd=%{time}',
  ids: {
    default: {
      pre: 568520,
    },
    audio: {
      pre: 1494845,
    },
  },
};

export const playerAnalyticsConfig = {
  cXense: {
    siteId: '1142969099341569069',
    origin: 'lth-delfi',
    persistedQueryId: '60134f11dac530e2cd5c308a42f829d82289a3b8',
  },
  eventsMap: {
    // jwPlayer event name: tracker event name
    play: 'audio_play',
    pause: 'audio_pause',
    idle: 'audio_stop',
    complete: 'audio_complete',
    seek: 'audio_seek',
  },
  trackProgressEvents: {
    audio_progress_10: 1,
    audio_progress_30: 1,
    audio_progress_70: 1,
  },
};

export default {
  seekBackInterval: -15,
  seekForwardInterval: 15,
  availablePlaybackRates: [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 2.0],
  features: {
    bigPlayer: {
      podcasts: {
        showNavigateToAll: true,
        showFooterButtons: true,
      },
    },
  },
};
