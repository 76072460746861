
import Vue from 'vue';
import PlayerTitle from './Title.vue';

interface Data {
  playbackRate: number;
  playbackRates: number[];
}

interface Methods {
  togglePlay: () => void;
  openBigPlayer: () => void;
  remove: () => void;
}

interface Computed {
  isBigPlayerOpen: boolean;
  isPlaying: boolean;
  currentListeningPercentage: number;
  title: string;
  description: string;
  img: {
    id?: string;
    plainSrc?: string;
  };
  playerPlaybackRate: number;
}

export default Vue.extend<Data, Methods, Computed, unknown>({
  components: {
    PlayerTitle,
  },
  data() {
    return {
      playbackRate: 1,
      playbackRates: [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 2.0],
    };
  },
  computed: {
    img() {
      return this.$player.state.currentlyPlaying.img || {};
    },
    title() {
      return this.$player.state.currentlyPlaying.title;
    },
    description() {
      return this.$player.state.currentlyPlaying.description;
    },
    isBigPlayerOpen() {
      return this.$player.state.showBigPlayer;
    },
    isPlaying() {
      return this.$player.state.isPlaying;
    },
    currentListeningPercentage() {
      return this.$player.state.currentlyPlaying.audioListeningPercentage;
    },
    playerPlaybackRate() {
      return this.$player.state.currentlyPlaying.playbackRate;
    },
  },
  watch: {
    playbackRate(newVal) {
      this.$player.setPlaybackRate(newVal);
    },
    playerPlaybackRate(newVal) {
      if (newVal !== this.playbackRate) {
        this.playbackRate = newVal;
      }
    },
  },
  methods: {
    remove() {
      this.$player.remove();
    },
    togglePlay() {
      if (this.isPlaying) {
        this.$player.pause();
      } else {
        this.$player.play();
      }
    },
    openBigPlayer() {
      this.$player.toggleBigPlayer();
    },
  },
});
