import { Plugin } from '@nuxt/types';
import documentReferrer from '@root/common/utils/documentReferrer';
import googleAnalytics from './googleAnalytics';
import cXensePlugin from './cXense';
import GemiusPlugin from './Gemius';
import FacebookPixel from './FacebookPixel';
import HotjarPlugin from './Hotjar';
import BbcTracker from './BbcTracker';
import ArticleViewStatisticsPlugin from './ArticleViewStatistics';
import GtmPlugin from './Gtm';
import GtagPlugin from './Gtag';

const analyticsTrackers: Plugin = (ctx, inject) => {
  ctx.app.router!.afterEach((to, from) => {
    if (from.name) {
      const host = ctx.app.$channelConfig('meta').base.baseUrl;
      documentReferrer(`${host}${from.fullPath}`);
    }
  });

  ctx.app.router?.onReady(() => {
    if (ctx.app.router?.currentRoute.query.preview) {
      return false;
    }

    googleAnalytics(ctx, inject);
    cXensePlugin(ctx, inject);
    GemiusPlugin(ctx, inject);
    FacebookPixel(ctx, inject);
    HotjarPlugin(ctx, inject);
    BbcTracker(ctx, inject);
    ArticleViewStatisticsPlugin(ctx, inject);
    GtmPlugin(ctx, inject);
    GtagPlugin(ctx, inject);
  });
};

export default analyticsTrackers;
