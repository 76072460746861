// https://docs.jwplayer.com/players/reference/setup-options
export default {
  mute: true,
  preload: 'auto',
  autoPause: {
    pauseAds: false,
    viewability: false,
  },
  cast: {},
  floating: {
    mode: 'never',
    showTitle: true,
    dismissible: true,
  },
  controls: true,
  aspectratio: '16:9',
  playbackRateControls: true,
  playbackRates: [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0],
  height: '100%',
  width: '100%',
  horizontalVolumeSlider: true,
  pipIcon: 'disabled',
  displaytitle: false,
  sharing: {
    sites: ['facebook', 'twitter', 'email'],
  },
  intl: {
    en: {
      advertising: {
        cuetext: '',
      },
    },
  },
};
