import { PlayerState } from './playerState';
import getCropperUrl from '../utils/getCropperUrl';
import playerConfig from '../config/player.config';
import { AudioItem, Player, ContainerAudioItem } from '../types/player';

interface PlayOptions {
  seekBackInterval: number;
  seekForwardInterval: number;
  playbackRate: number;
}

interface ContainerWindow extends Window {
  AndroidWebAppMobileAPI: {
    setLoginApiToken: (token: string) => void;
    startPlaying: (audioItem: string, playOptions: string) => void;
    startPlayingPlaylist: (audioItem: string, playOptions: string) => void;
    play: () => void;
    pause: () => void;
    seekTo: (position: string) => void;
    changeTitle: (title: string) => void;
    setPlaybackRate: (playbackRate: string) => void;
    prev: () => void;
    next: () => void;
    remove(): void;
  };
}

declare let window: ContainerWindow;

export class ContainerPlayer extends PlayerState implements Player {
  private readonly _mediaApiCropperUrl: string = '';
  constructor(mediaApiCropperUrl: string) {
    super();
    this._mediaApiCropperUrl = mediaApiCropperUrl;
  }
  setCustomerToken(token: string) {
    window.AndroidWebAppMobileAPI.setLoginApiToken(token);
  }
  setup(audioItem: AudioItem) {
    super.setState(audioItem);
    const stateAudioItem = this.state.currentlyPlaying;

    const containerAudioItem: ContainerAudioItem = {
      audioId: stateAudioItem.audioId,
      url: stateAudioItem.file,
      imgUrl: getCropperUrl({ id: stateAudioItem.img.id || '', cropperUrl: this._mediaApiCropperUrl, cropperData: { w: 240, r: '1:1' } }),
      title: stateAudioItem.title,
      description: stateAudioItem.description,
      audioDuration: stateAudioItem.audioDuration,
      position: Math.floor(stateAudioItem.audioPosition),
      descriptionLink: stateAudioItem.descriptionLink,
      articleId: stateAudioItem.articleId,
    };

    const playOptions: PlayOptions = {
      seekBackInterval: playerConfig.seekBackInterval,
      seekForwardInterval: playerConfig.seekForwardInterval,
      playbackRate: stateAudioItem.playbackRate,
    };

    window.AndroidWebAppMobileAPI.startPlaying(JSON.stringify(containerAudioItem), JSON.stringify(playOptions));
    return true;
  }
  play() {
    super.play();
    window.AndroidWebAppMobileAPI.play();
  }
  pause() {
    super.pause();
    window.AndroidWebAppMobileAPI.pause();
  }
  remove() {
    super.remove();
    window.AndroidWebAppMobileAPI.remove();
  }
  seek(position: number) {
    window.AndroidWebAppMobileAPI.seekTo(String(Math.floor(position)));
  }
  seekBack() {
    this.seek(this.getSeekBackPosition());
  }
  seekForward() {
    this.seek(this.getSeekForwardPosition());
  }
  changeTitle(title: string) {
    super.changeTitle(title);
    window.AndroidWebAppMobileAPI.changeTitle(title);
  }
  setPlaybackRate(rate: number) {
    super.setPlaybackRate(rate);
    window.AndroidWebAppMobileAPI.setPlaybackRate(String(rate));
  }
}
