import productionChannelConfig from './productionChannelConfig';
import testChannelConfig from './testChannelConfig';

// All domains which are used in Delfi are listed in here
export const domains = [
  'www.delfi.lt',
  'www.delfi.lt/agro',
  'www.delfi.lt/auto',
  'www.delfi.lt/bustas',
  'www.delfi.lt/darbas',
  'www.delfi.lt/en',
  'www.delfi.lt/fit',
  'www.delfi.lt/fone',
  'www.delfi.lt/grynas',
  'www.delfi.lt/gyvenimas',
  'www.delfi.lt/kablys',
  'www.delfi.lt/kaledos',
  'www.delfi.lt/kartu',
  'www.delfi.lt/keliones',
  'www.delfi.lt/krepsinis',
  'www.delfi.lt/kultura',
  'www.delfi.lt/letena',
  'www.delfi.lt/login',
  'www.delfi.lt/m360',
  'www.delfi.lt/maistas',
  'www.delfi.lt/miestai',
  'www.delfi.lt/mokslas',
  'www.delfi.lt/moterys',
  'www.delfi.lt/multimedija',
  'www.delfi.lt/nematomi',
  'www.delfi.lt/orai',
  'www.delfi.lt/pl',
  'www.delfi.lt/plius',
  'www.delfi.lt/projektai',
  'www.delfi.lt/ru',
  'www.delfi.lt/saugu',
  'www.delfi.lt/seima',
  'www.delfi.lt/sportas',
  'www.delfi.lt/stilius',
  'www.delfi.lt/sveikata',
  'www.delfi.lt/tvarilietuva',
  'www.delfi.lt/uzsakomasis-turinys',
  'www.delfi.lt/verslasplius',
  'www.delfi.lt/verslo-poziuris',
  'www.delfi.lt/vasara',
  'www.delfi.lt/veidai',
  'www.delfi.lt/verslas',
  'www.delfi.lt/verslasplius',
  'www.delfi.lt/tv',
  'www.delfi.lt/visikeliai',
  // fake channels
  'www.delfi.lt/aktualijos',
  'www.delfi.lt/laisvalaikis',
];

const envConfig: { [key: string]: Channel[] } = {
  production: productionChannelConfig,
  review: productionChannelConfig,
  test: testChannelConfig,
};

const channelEnv = process.env.CHANNEL_CONFIG_ENV || 'production';

export default envConfig[channelEnv] as Channel[];

export interface Channel {
  name: string;
  external_id?: string;
  domain: string;
  path: string;
  url: string;
  locale: string;
  tag_channel?: boolean;
  old_type_redirects: boolean;
  old_type_redirects_dev: boolean;
}
