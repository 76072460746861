import channels, { Channel } from '@root/modules/channel/config/channel.config';
import rootConfig from '@root/config/root.config';
import matchChannel from '@root/common/utils/matchChannel';
import { Data as HeadlinesData } from '@root/modules/headlines/types/headlines';
import { Data as ArticleData } from '@root/modules/article/types/article';
import hasNapoComments from '@root/modules/article/utils/hasNapoComments';
import { buildQueryUrl } from '@root/router/utils/buildQueryUrl';
import { Context } from '@nuxt/types';

type BuildArticleUrl = ({
  article,
  domain,
  fakeDomainEnabled,
  articlesLinksHash,
}: {
  article: HeadlinesData | ArticleData;
  domain?: string | string[];
  fakeDomainEnabled?: boolean;
  articlesLinksHash?: string;
  query?: Context['route']['query'];
  route?: Context['route'];
}) => string;

function generatePath(article: HeadlinesData | ArticleData) {
  const items = [];

  const channel = article.primaryCategory?.channel;
  const channelConfig = channels.find((ch) => ch.url === channel.url) || null;

  const channelPath = (channel && channel.path) || rootConfig.main_slug;
  if (channelPath !== null) {
    items.push(channelPath);
  }

  // FIXME: could be channelConfig.external_id int to get rid of this .toString()?
  const parentCategory = article.primaryCategory.parentCategory || null;
  if (parentCategory && parentCategory.id && parentCategory.id.toString() !== channelConfig?.external_id) {
    items.push(parentCategory.slug);
  }

  const categorySlug = article.primaryCategory.slug;
  items.push(categorySlug);

  return items.join('/');
}

function buildArticleDevUrl(article: HeadlinesData | ArticleData, channelConfig: Channel) {
  const origin = process.client ? document.location.origin : '';
  const path = generatePath(article);
  return channelConfig.old_type_redirects_dev
    ? `https://${channelConfig.domain}/a/${article.id}`
    : `${origin}/${path ? path + '/' : ''}${article.slug}-${article.id}`;
}

function buildArticleLiveUrl(article: HeadlinesData | ArticleData, channelConfig: Channel) {
  const path = generatePath(article);
  const isTest = typeof window === 'undefined' ? process.env.CHANNEL_CONFIG_ENV === 'test' : /.test.delfi/.test(document.location.hostname);
  const domain = isTest ? channelConfig.domain.replace('.delfi', '.test.delfi') : channelConfig.domain;
  return channelConfig.old_type_redirects ? `https://${domain}/a/${article.id}` : `https://${domain}/${path ? path + '/' : ''}${article.slug}-${article.id}`;
}

const buildArticleUrl: BuildArticleUrl = ({ article, domain, fakeDomainEnabled, articlesLinksHash, query, route }) => {
  const channel = article.primaryCategory?.channel;

  if (!channel) {
    return '';
  }

  // Handle Receptai channel articles redirect
  if (channel.url === 'www.delfi.lt/receptai') {
    return `https://www.delfi.lt/receptai/a/${article.id}`;
  }

  const isDev = fakeDomainEnabled;
  const path = generatePath(article);

  let articleUrl = `/${path}/${article.slug}-${article.id}`;

  const sameChannel = domain ? matchChannel(domain, channel.url) : true;
  if (!sameChannel) {
    // TODO: Change after Content-api channel object will return channel url
    const channelConfig = channels.find((ch) => ch.url === channel.url) || null;

    if (channelConfig) {
      articleUrl = isDev ? buildArticleDevUrl(article, channelConfig) : buildArticleLiveUrl(article, channelConfig);
    } else {
      articleUrl = `${rootConfig.main_portal_url}/a/${article.id}`;
    }
  }

  if (query) {
    const queryUrl = buildQueryUrl(articleUrl, { query, route });
    articleUrl = queryUrl;
  }

  return `${articleUrl}${articlesLinksHash || ''}`;
};

const buildArticleCommentsUrl: BuildArticleUrl = ({ article, domain, fakeDomainEnabled, articlesLinksHash }) => {
  const articleUrl = buildArticleUrl({ article, domain, fakeDomainEnabled, articlesLinksHash });
  const articleCommentsUrl = hasNapoComments(article) ? `${articleUrl}/napo` : `${articleUrl}/diskusija`;

  return articleCommentsUrl;
};

export { buildArticleCommentsUrl };
export default buildArticleUrl;
