import jwplayerTypes from '@root/modules/podcast/types/jwPlayer';
import { CurrentlyPlaying } from '@root/modules/podcast/types/player';
import cXense from '@analytics/trackers/cXense/cXense';
import { playerAnalyticsConfig } from '@root/modules/podcast/config/player.config';
import PlayerAnalyticsBase from './PlayerAnalyticsBase';
import { loadScripts } from '@root/common/utils/scriptLoader';

interface cXWindow extends Window {
  [index: string]: any;
}

declare let window: cXWindow;

class cXenseTracker extends PlayerAnalyticsBase {
  private _cX!: cXense;
  private _options = { origin: playerAnalyticsConfig.cXense.origin, persistedQueryId: playerAnalyticsConfig.cXense.persistedQueryId };

  constructor(player: jwplayerTypes.JWPlayer) {
    super(player);
  }

  async initAnalytics() {
    if (!window.cX) {
      try {
        await loadScripts('//cdn.cxense.com/cx.js', { async: true });
      } catch (e) {
        console.error('cXense script loading failed', e);
      }
    }

    this._cX = new cXense();
    await this._cX.init({ uid: playerAnalyticsConfig.cXense.siteId });
    this.setEventsListeners();
  }

  public sendEvent(eventName: string) {
    const parameters = this.eventParameters();
    this._cX?.sendEvent(eventName, parameters, this._options);
  }
}

export default async (jwplayer: jwplayerTypes.JWPlayer, currentlyPlaying: CurrentlyPlaying) => {
  const player: jwplayerTypes.JWPlayer | null = jwplayer;

  let trackers: Record<string, unknown> = {};

  if (player) {
    const cXense: cXenseTracker = await new cXenseTracker(player);
    cXense.setCurrentlyPlaying(currentlyPlaying);

    trackers = {
      cXense,
    };
  }

  return trackers;
};
