import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import { jwPlayerCdnUrl, jwPlayerKey, apiUrl } from '@root/modules/podcast/config/player.config';
import { loadScripts } from '@root/common/utils/scriptLoader';
import { JwPlayer } from '@root/modules/podcast/utils/jwPlayer';
import { ContainerPlayer } from '@root/modules/podcast/utils/ContainerPlayer';
import { PlayerPlugin, Player, ContainerAudioItem, AudioItem } from '@root/modules/podcast/types/player';
import { clearLastPlayed } from '@root/modules/podcast/utils/storage';
import getMediaData from '@root/modules/podcast/utils/getMediaData';
import { getPlayTimeFromStorage, saveLastPlayed } from '@root/modules/podcast/utils/storage';
import buildPodcastUrl from '@root/modules/podcast/utils/buildPodcastUrl';
import { Podcast } from '@root/modules/podcast/types/podcast';
import PodcastEpisodeService from '@root/modules/podcast/services/podcastEpisode.service';
import { limit } from '@root/modules/podcast/config/podcast.config';

interface ContainerPlayerChangedEvent {
  audioItem: ContainerAudioItem;
  isPlaying: boolean;
  playbackRate: number;
}

interface CustomWindow extends Window {
  onPlayerStateChanged: (event: ContainerPlayerChangedEvent) => void;
}

declare let window: CustomWindow;

// Inject into Vue instances (this.$player)
declare module 'vue/types/vue' {
  interface Vue {
    $player: PlayerPlugin;
  }
}

const playerPlugin: Plugin = async (ctx, inject) => {
  const { store, app } = ctx;
  let player: Player | null = null;
  let customerDataSaveInterval: any = undefined;
  const useCustomerData = false;

  const setup: PlayerPlugin['setup'] = async (episode) => {
    let audioItem: AudioItem | null = null;

    const customerToken = store.state.piano.token;
    const fakeDomainEnabled = app.$config.FAKE_DOMAIN_ENABLED;
    const { domain } = app.$channelConfig('settings');
    const mediaData = await getMediaData([episode], customerToken);

    if (mediaData?.length) {
      const { assetType, assetUrl } = mediaData[0];

      if (assetType && assetUrl) {
        const audioId = episode.content.leadElement.content[0].attrs.id;

        audioItem = {
          file: assetUrl,
          title: episode.content.title.text as string,
          description: episode.content.lead.text as string,
          descriptionLink: buildPodcastUrl({ article: episode, domain, fakeDomainEnabled }),
          img: { id: episode.metaImage?.id },
          audioId,
          audioPosition: getPlayTimeFromStorage(audioId)?.playTime ?? 0,
          assetType,
          articleId: episode.id,
          parentTitle: episode.primaryCategory.parentCategory.name,
          categoryTitle: episode.primaryCategory.name,
          author: episode.primaryCategory.attributes?.categoryAuthor,
          autoPlay: true,
        };

        // saveLastPlayed item data for resume playing
        saveLastPlayed(episode);
      }
    }

    if (!audioItem) {
      return;
    }

    const playerSetup = await player?.setup(audioItem);

    if (!playerSetup) {
      return;
    }

    if (!useCustomerData) {
      return;
    }

    const parsedAudioId = player?.state.currentlyPlaying.audioId;

    try {
      await ctx.app.$customerData.createLastListenedId(parsedAudioId);
    } catch (e) {
      console.log(e);
    }

    await ctx.app.$customerData.getListenedDuration(parsedAudioId);

    clearInterval(customerDataSaveInterval);
    customerDataSaveInterval = setInterval(async () => {
      try {
        await ctx.app.$customerData.handleListenedDurationUpdate({
          input: {
            key: parsedAudioId,
            value: String(player?.state.currentlyPlaying.audioPosition),
          },
        });
      } catch (e) {
        console.log(e);
      }
    }, 10 * 1000);
  };

  const togglePlayPauseIfNeeded: PlayerPlugin['togglePlayPauseIfNeeded'] = ({ audioItemId, playlist }) => {
    const isCurrentEpisodeActive = playlist
      ? Boolean(playlist.find((item) => item.audioId === player?.state.currentlyPlaying.audioId))
      : player?.state.currentlyPlaying.audioId === String(audioItemId);

    if (isCurrentEpisodeActive) {
      if (player?.state.isPlaying) {
        player?.pause();
      } else {
        player?.play();
      }

      return true;
    }

    return false;
  };

  const play: PlayerPlugin['play'] = () => {
    player?.play();
  };

  const pause: PlayerPlugin['pause'] = () => {
    player?.pause();
  };

  const seek: PlayerPlugin['seek'] = (position) => {
    player?.seek(position);
  };

  const seekBack: PlayerPlugin['seekBack'] = () => {
    player?.seekBack();
  };

  const seekForward: PlayerPlugin['seekForward'] = () => {
    player?.seekForward();
  };

  const remove: PlayerPlugin['remove'] = () => {
    // Clear last played
    clearLastPlayed();

    player?.remove();
  };

  const changeTitle: PlayerPlugin['changeTitle'] = (title) => {
    player?.changeTitle(title);
  };

  const setPlaybackRate: PlayerPlugin['setPlaybackRate'] = (playbackRate) => {
    player?.setPlaybackRate(playbackRate);
  };

  const prev: PlayerPlugin['prev'] = async () => {
    const playlist: Podcast[] = store.getters['podcast/getActivePlaylist'];
    const current = playlist.find((item) => item.id === player?.state.currentlyPlaying.articleId);
    if (current) {
      const prev = playlist[playlist.indexOf(current) - 1];
      if (prev) {
        await setup(prev);
      }
    }
  };

  const next: PlayerPlugin['next'] = async () => {
    const playlist: Podcast[] = store.getters['podcast/getActivePlaylist'];
    const current = playlist.find((item) => item.id === player?.state.currentlyPlaying.articleId);
    if (current) {
      const next = playlist[playlist.indexOf(current) + 1];
      if (next) {
        await setup(next);

        // Check if there's more episodes to load in playlist after starting to play last playlist item
        console.log(next.id, playlist[playlist.length - 1].id);
        if (next.id === playlist[playlist.length - 1].id) {
          const items = await new PodcastEpisodeService().fetch({ categoryId: [next.primaryCategory.id], offset: playlist.length, limit });
          if (items?.length) {
            store.dispatch('podcast/setPlaylist', [...playlist, ...items]);
          }
        }
      }
    }
  };

  const toggleBigPlayer: PlayerPlugin['toggleBigPlayer'] = () => {
    player!.toggleBigPlayer();
  };

  const useContainerPlayer = false;
  if (useContainerPlayer) {
    player = new ContainerPlayer(apiUrl.mediaApiImageCropperUrl);

    store.watch(
      (state) => state.piano.access,
      async () => {
        (player as ContainerPlayer).setCustomerToken(store.state.piano.token);
      }
    );

    const customerToken = store.state.piano.token;

    if (customerToken) {
      (player as ContainerPlayer).setCustomerToken(customerToken);
    }

    window.onPlayerStateChanged = (event) => {
      const audioItem = event.audioItem;

      player!.setState({
        file: audioItem.url,
        audioId: audioItem.audioId,
        title: audioItem.title,
        description: audioItem.description,
        img: {
          plainSrc: audioItem.imgUrl,
        },
        // Placeholder value. Is not used in ContainerPlayer setup
        assetType: 'podcasts',
        articleId: audioItem.articleId,
        audioDuration: audioItem.audioDuration,
        audioPosition: audioItem.position,
        descriptionLink: audioItem.descriptionLink,
      });

      if (player!.state.currentlyPlaying.playbackRate !== event.playbackRate) {
        player!.setPlaybackRate(event.playbackRate);
      }

      if (player!.state.isPlaying !== event.isPlaying) {
        player!.state.isPlaying = event.isPlaying;
      }
    };
  } else {
    await loadScripts(jwPlayerCdnUrl, { async: true });

    player = new JwPlayer(jwPlayerKey, apiUrl.mediaApiImageCropperUrl);
  }

  // Listen for player complete event to advance to next playlist item
  window.addEventListener('onPlayerComplete', () => {
    next();
  });

  const playerInject: PlayerPlugin = {
    state: Vue.observable(player!.state),
    setup,
    remove,
    play,
    pause,
    seek,
    seekBack,
    seekForward,
    changeTitle,
    setPlaybackRate,
    prev,
    next,
    togglePlayPauseIfNeeded,
    toggleBigPlayer,
  };

  inject('player', playerInject);
};

export default playerPlugin;
