import MediaApiService from '@root/modules/podcast/services/mediaApi.service';
import PodcastEpisodeService from '@root/modules/podcast/services/podcastEpisode.service';
import { convertArrayToObject } from '@root/common/utils/helpers';
import { Podcast, MediaData } from '@root/modules/podcast/types/podcast';
import getAssetType from '@root/modules/podcast/utils/getAssetType';

const getMediaData = async (episodes: Podcast[], customerToken?: string | null, getPlaylistLocation: boolean = true): Promise<MediaData[] | null> => {
  const mediaData: MediaData[] = [];
  const mediaAssets = [];
  const articleAssets = [];

  for (const episode of episodes) {
    const assetType = getAssetType(episode);
    switch (assetType) {
      case 'podcasts':
      case 'audiobooks':
        mediaAssets.push(episode.content?.leadElement?.content[0]?.attrs?.id);
        mediaData.push({ id: episode.id, assetId: episode.content?.leadElement?.content[0]?.attrs?.id, assetType });
        break;
      case 'articles':
        articleAssets.push(episode.id);
        mediaData.push({ id: episode.id, assetId: episode.id, assetType });
        break;
    }
  }

  if (mediaAssets.length) {
    const mediaApiService = new MediaApiService();
    const mediaItems = await mediaApiService.fetch({ id: mediaAssets, getPlaylistLocation });
    if (mediaItems?.length) {
      for (const mediaItem of mediaItems) {
        const assetId = mediaItem?.id;
        const assetUrl = mediaItem?.audioData?.audioPlaylistLocation || null;
        const assetDuration = mediaItem?.audioData?.audioDuration || null;
        const assetIndex = mediaData.map((m) => m.assetId).indexOf(assetId);

        if (mediaData[assetIndex]) {
          mediaData[assetIndex] = { ...mediaData[assetIndex], assetUrl, assetDuration };
        }
      }
    }
  }

  if (articleAssets.length) {
    const podcastEpisodeService = new PodcastEpisodeService();

    if (customerToken) {
      podcastEpisodeService.setCustomerToken(customerToken);
    }

    const articleItems = await podcastEpisodeService.fetch({ id: articleAssets });
    if (articleItems?.length) {
      for (const articleItem of articleItems) {
        const ttsSettings = convertArrayToObject<Podcast['ttsSettings']>(articleItem.ttsSettings);
        const assetId = articleItem?.id;
        const assetIndex = mediaData.map((m) => m.assetId).indexOf(assetId);
        const assetUrl = ttsSettings.textToSpeech || ttsSettings.textToSpeechTeaser;

        if (mediaData[assetIndex]) {
          mediaData[assetIndex].assetUrl = assetUrl;
        }
      }
    }
  }

  return mediaData;
};

export default getMediaData;
