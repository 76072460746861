import stringTemplate from '@ekd/js-stringtemplate';
import { kebabCase } from 'lodash';

import { PlayerState } from './playerState';
import { AudioItem, Player } from '../types/player';
import jwplayerTypes from '../types/jwPlayer';
import getCropperUrl from '../utils/getCropperUrl';
import initPlayerAnalytics from '../utils/playerAnalytics';
import jwPlayerSettings from './jwPlayerSettings';
import { playerAdsConfig } from '../config/player.config';
import canAutoplay from 'can-autoplay';

type VastType = 'pre';
type Vast = 'audio';

interface AdsSchedule {
  offset: string | number;
  tag: string;
  pod: any[];
}

interface CustomWindow extends Window {
  jwplayer: JWPlayerStatic;
}

declare let window: CustomWindow;

interface AdsData {
  keywords?: string[];
}

export class JwPlayer extends PlayerState implements Player {
  private readonly _jwPlayerKey: string = '';
  private readonly _mediaApiCropperUrl: string = '';
  jwplayer: jwplayerTypes.JWPlayer | null = null;
  analyticsTrackers: Record<string, any> | null = null;

  constructor(jwPlayerKey: string, mediaApiCropperUrl: string) {
    super();
    this._jwPlayerKey = jwPlayerKey;
    this._mediaApiCropperUrl = mediaApiCropperUrl;
    this.getPlayerWindowInstance();
  }

  private getPlayerWindowInstance() {
    const { jwplayer } = window;
    this.jwplayer = jwplayer('jwplayer-container');
  }
  private getAdsObject(type: Vast, adsData?: AdsData) {
    const ads = playerAdsConfig;
    const vastData = ads.ids[type] || ads.ids.default;
    const time = +new Date();
    const schedule: AdsSchedule[] = [];

    Object.keys(vastData).forEach((key) => {
      const mkw = (adsData?.keywords || []).join(',') || '';
      const vastSchedule = {
        offset: key,
        tag: stringTemplate(ads.vastUrl, { vastId: vastData[key as VastType], time, mkw }),
        pod: [],
      };

      schedule.push(vastSchedule);
    });

    return {
      client: 'vast',
      autoplayadsmuted: true,
      preloadAds: true,
      schedule,
    };
  }
  getPlayerSettings(mediaInfo: { file?: string; title?: string; image?: string; keywords?: string[] }) {
    const { keywords = [], ...restMediaInfo } = mediaInfo;
    const advertising = this.getAdsObject('audio', { keywords });

    return {
      ...jwPlayerSettings,
      ...restMediaInfo,
      advertising,
      key: this._jwPlayerKey,
    };
  }
  async setup(audioItem: AudioItem) {
    const { img, file, title } = audioItem;
    const image = getCropperUrl({ id: img.id || '', cropperUrl: this._mediaApiCropperUrl, cropperData: { w: 240, r: '1:1' } });

    return new Promise<boolean>((resolve) => {
      const stateAudioItem = { ...audioItem };
      const keywords: string[] = [];
      const parentTitleSlug = kebabCase(stateAudioItem.parentTitle || '');

      if (parentTitleSlug && stateAudioItem.assetType === 'podcasts') {
        keywords.push(`audio-collection-name-${parentTitleSlug}`);
      }

      keywords.push(stateAudioItem.assetType);

      this.jwplayer?.setup(this.getPlayerSettings({ file, title, image, keywords }));

      if (!stateAudioItem.audioDuration) {
        stateAudioItem.audioDuration = this.jwplayer?.getDuration() || 0;
      }

      this.jwplayer?.on('ready', async () => {
        super.setState(stateAudioItem);

        this.clearAnalyticsTrackers();
        await this.initAnalyticsTrackers();
        this.setAnalyticsCurrentlyPlaying();

        this.setTimeListener();

        const playbackRate = this.state.currentlyPlaying.playbackRate;

        if (playbackRate !== 1) {
          this.jwplayer?.setPlaybackRate(playbackRate);
        }

        if (this.state.currentlyPlaying.autoPlay) {
          canAutoplay.audio().then(({ result }) => {
            if (result === true) {
              this.play();
            }
          });
        }

        resolve(true);
      });

      this.jwplayer?.on('firstFrame', () => {
        if (this.state.currentlyPlaying.audioPosition > 0) {
          this.jwplayer?.seek(this.state.currentlyPlaying.audioPosition);
        }
      });

      this.jwplayer?.on('complete', () => {
        window.dispatchEvent(new CustomEvent('onPlayerComplete'));
      });
    });
  }
  play() {
    super.play();
    this.jwplayer?.play();
  }
  pause() {
    super.pause();
    this.jwplayer?.pause();
  }
  remove() {
    super.remove();
    this.jwplayer?.remove();
    this.getPlayerWindowInstance();
  }
  seek(position: number) {
    this.jwplayer?.seek(position);
  }
  seekBack() {
    this.seek(this.getSeekBackPosition());
  }
  seekForward() {
    this.seek(this.getSeekForwardPosition());
  }
  clearAnalyticsTrackers() {
    this.analyticsTrackers = null;
  }
  changeTitle(title: string) {
    super.changeTitle(title);
    this.analyticsTrackers?.cXense?.setCurrentlyPlaying(this.state.currentlyPlaying);
  }
  async initAnalyticsTrackers() {
    if (!this.analyticsTrackers) {
      this.analyticsTrackers = await initPlayerAnalytics(this.jwplayer!, this.state.currentlyPlaying);
      return;
    }
  }
  setAnalyticsCurrentlyPlaying() {
    this.analyticsTrackers?.cXense?.setCurrentlyPlaying(this.state.currentlyPlaying);
  }
  setPlaybackRate(rate: number) {
    super.setPlaybackRate(rate);
    this.jwplayer?.setPlaybackRate(rate);
  }
  private setTimeListener() {
    this.jwplayer?.on('time', (event) => {
      this.state.isAdPlaying = false;
      this.state.currentlyPlaying.audioDuration = event.duration;
      this.state.currentlyPlaying.audioPosition = event.position;
      this.state.currentlyPlaying.audioListeningPercentage = (event.position / event.duration) * 100;
    });

    this.jwplayer?.on('adTime', (event) => {
      this.state.isAdPlaying = true;
      this.state.currentlyPlaying.audioDuration = event.duration;
      this.state.currentlyPlaying.audioPosition = event.position;
      this.state.currentlyPlaying.audioListeningPercentage = (event.position / event.duration) * 100;
    });
  }
}
