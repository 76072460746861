// Blocktypes
import BlockType1 from '../components/block/1-10/BlockType1.vue';
import BlockType2 from '../components/block/1-10/block-type-2/BlockType2.vue';
import BlockType3 from '../components/block/1-10/block-type-3/BlockType3.vue';
import BlockType4 from '../components/block/1-10/block-type-4/BlockType4.vue';
import BlockType5 from '../components/block/1-10/BlockType5.vue';
import BlockType6 from '../components/block/1-10/BlockType6.vue';
import BlockType7 from '../components/block/1-10/BlockType7.vue';
import BlockType8 from '../components/block/1-10/BlockType8.vue';
import BlockType9 from '../components/block/1-10/BlockType9.vue';
import BlockType10 from '../components/block/1-10/BlockType10.vue';
import BlockType11 from '../components/block/11-20/block-type-11/BlockType11.vue';
import BlockType12 from '../components/block/11-20/BlockType12.vue';
import BlockType13 from '../components/block/11-20/block-type-13/BlockType13.vue';
import BlockType14 from '../components/block/11-20/block-type-14/BlockType14.vue';
import BlockType15 from '../components/block/11-20/BlockType15.vue';
import BlockType16 from '../components/block/11-20/BlockType16.vue';
import BlockType17 from '../components/block/11-20/BlockType17.vue';
import BlockType18 from '../components/block/11-20/BlockType18.vue';
import BlockType19 from '../components/block/11-20/block-type-19/BlockType19.vue';
import BlockType20 from '../components/block/11-20/BlockType20.vue';
import BlockType21 from '../components/block/21-30/BlockType21.vue';
import BlockType22 from '../components/block/21-30/BlockType22.vue';
import BlockType23 from '../components/block/21-30/block-type-23/BlockType23.vue';
import BlockType24 from '../components/block/21-30/BlockType24.vue';
import BlockType25 from '../components/block/21-30/BlockType25.vue';
import BlockType26 from '../components/block/21-30/BlockType26.vue';
import BlockType27 from '../components/block/21-30/block-type-27/BlockType27.vue';
import BlockType28 from '../components/block/21-30/BlockType28.vue';
import BlockType29 from '../components/block/21-30/BlockType29.vue';
import BlockType101 from '../components/block/100-110/BlockType101.vue';
import BlockType102 from '../components/block/100-110/block-type-102/BlockType102.vue';
import BlockType103 from '../components/block/100-110/block-type-103/BlockType103.vue';
import BlockType104 from '../components/block/100-110/BlockType104.vue';
import BlockType105 from '../components/block/100-110/BlockType105.vue';
import BlockType106 from '../components/block/100-110/BlockType106.vue';
import BlockType107 from '../components/block/100-110/BlockType107.vue';
import BlockType108 from '../components/block/100-110/BlockType108.vue';
import BlockType109 from '../components/block/100-110/BlockType109.vue';
import BlockType110 from '../components/block/100-110/BlockType110.vue';
import BlockType111 from '../components/block/111-120/BlockType111.vue';
import BlockType112 from '../components/block/111-120/BlockType112.vue';
import BlockType113 from '../components/block/111-120/BlockType113.vue';
import BlockType114 from '../components/block/111-120/BlockType114.vue';

// admin block types
import AdminBlockType1 from '@headlines/config/block/1-10/blockType1.config';
import AdminBlockType2 from '@headlines/config/block/1-10/blockType2.config';
import AdminBlockType3 from '@headlines/config/block/1-10/blockType3.config';
import AdminBlockType4 from '@headlines/config/block/1-10/blockType4.config';
import AdminBlockType5 from '@headlines/config/block/1-10/blockType5.config';
import AdminBlockType6 from '@headlines/config/block/1-10/blockType6.config';
import AdminBlockType7 from '@headlines/config/block/1-10/blockType7.config';
import AdminBlockType8 from '@headlines/config/block/1-10/blockType8.config';
import AdminBlockType9 from '@headlines/config/block/1-10/blockType9.config';
import AdminBlockType10 from '@headlines/config/block/1-10/blockType10.config';
import AdminBlockType11 from '@headlines/config/block/11-20/blockType11.config';
import AdminBlockType12 from '@headlines/config/block/11-20/blockType12.config';
import AdminBlockType13 from '@headlines/config/block/11-20/blockType13.config';
import AdminBlockType14 from '@headlines/config/block/11-20/blockType14.config';
import AdminBlockType15 from '@headlines/config/block/11-20/blockType15.config';
import AdminBlockType16 from '@headlines/config/block/11-20/blockType16.config';
import AdminBlockType17 from '@headlines/config/block/11-20/blockType17.config';
import AdminBlockType18 from '@headlines/config/block/11-20/blockType18.config';
import AdminBlockType19 from '@headlines/config/block/11-20/blockType19.config';
import AdminBlockType20 from '@headlines/config/block/11-20/blockType20.config';
import AdminBlockType23 from '@headlines/config/block/21-30/blockType23.config';
import AdminBlockType24 from '@headlines/config/block/21-30/blockType24.config';
import AdminBlockType25 from '@headlines/config/block/21-30/blockType25.config';
import AdminBlockType26 from '@headlines/config/block/21-30/blockType26.config';
import AdminBlockType28 from '@headlines/config/block/21-30/blockType28.config';
import AdminBlockType29 from '@headlines/config/block/21-30/blockType29.config';
import AdminBlockType30 from '@headlines/config/block/21-30/blockType30.config';
import AdminBlockType101 from '@headlines/config/block/100-110/blockType101.config';
import AdminBlockType102 from '@headlines/config/block/100-110/blockType102.config';
import AdminBlockType104 from '@headlines/config/block/100-110/blockType104.config';
import AdminBlockType110 from '@headlines/config/block/100-110/blockType110.config';
import AdminBlockType113 from '@headlines/config/block/111-120/blockType113.config';
import AdminBlockType114 from '@headlines/config/block/111-120/blockType114.config';
import AdminBlockWeatherWidget from '@headlines/config/block/blockWeatherWidget.config';

// Admin block selector component
import AdminBlockSelector from '../components/block/AdminBlockSelector.vue';

const BlockCollection = {
  BlockType1,
  BlockType2,
  BlockType3,
  BlockType4,
  BlockType5,
  BlockType6,
  BlockType7,
  BlockType8,
  BlockType9,
  BlockType10,
  BlockType11,
  BlockType12,
  BlockType13,
  BlockType14,
  BlockType15,
  BlockType16,
  BlockType17,
  BlockType18,
  BlockType19,
  BlockType20,
  BlockType21,
  BlockType22,
  BlockType23,
  BlockType24,
  BlockType25,
  BlockType26,
  BlockType27,
  BlockType28,
  BlockType29,
  BlockType101,
  BlockType102,
  BlockType103,
  BlockType104,
  BlockType105,
  BlockType106,
  BlockType107,
  BlockType108,
  BlockType109,
  BlockType110,
  BlockType111,
  BlockType112,
  BlockType113,
  BlockType114,
};

const AdminBlockCollection = {
  BlockType1: AdminBlockType1,
  BlockType2: AdminBlockType2,
  BlockType3: AdminBlockType3,
  BlockType4: AdminBlockType4,
  BlockType5: AdminBlockType5,
  BlockType6: AdminBlockType6,
  BlockType7: AdminBlockType7,
  BlockType8: AdminBlockType8,
  BlockType9: AdminBlockType9,
  BlockType10: AdminBlockType10,
  BlockType11: AdminBlockType11,
  BlockType12: AdminBlockType12,
  BlockType13: AdminBlockType13,
  BlockType14: AdminBlockType14,
  BlockType15: AdminBlockType15,
  BlockType16: AdminBlockType16,
  BlockType17: AdminBlockType17,
  BlockType18: AdminBlockType18,
  BlockType19: AdminBlockType19,
  BlockType20: AdminBlockType20,
  BlockType23: AdminBlockType23,
  BlockType24: AdminBlockType24,
  BlockType25: AdminBlockType25,
  BlockType26: AdminBlockType26,
  BlockType28: AdminBlockType28,
  BlockType29: AdminBlockType29,
  BlockType30: AdminBlockType30,
  BlockType101: AdminBlockType101,
  BlockType102: AdminBlockType102,
  BlockType104: AdminBlockType104,
  BlockType110: AdminBlockType110,
  BlockType113: AdminBlockType113,
  BlockType114: AdminBlockType114,
  BlockWeatherWidget: AdminBlockWeatherWidget,
};

// Headlines fragments
export * from '../components/fragments';

export { BlockCollection, AdminBlockCollection, AdminBlockSelector };
