import BlockType114Component from '@headlines/components/block/111-120/BlockType114.vue';
import { AdminBlockType } from '@headlines/types/block/adminBlockType';
import AdminBlockTypeAttributes from '@headlines/config/block/shared/attributes/AdminBlockTypeAttributes';

const AdminBlockType114: AdminBlockType = {
  component: BlockType114Component,
  attributes: AdminBlockTypeAttributes({
    icons: true,
    author: false,
    titleComment: true,
    channel: false,
    category: false,
    lead: false,
    publishDate: false,
    slidesPerBreakpoint: '1.85,3.5,4.5',
    autoplay: false,
    loop: false,
  }),
  articlesCount: 15,
};

export default AdminBlockType114;
