import channels, { Channel } from '@root/modules/channel/config/channel.config';
import rootConfig from '@root/config/root.config';
import { Data as CategoriesData } from '@root/modules/category/types/categories';
import matchChannel from '@root/common/utils/matchChannel';

interface Process {
  client: boolean;
  env: any;
}

declare let process: Process;

type BuildCategoryUrl = ({
  category,
  domain,
  fakeDomainEnabled,
}: {
  category: CategoriesData;
  domain?: string | string[];
  fakeDomainEnabled?: boolean;
}) => string;

function generatePath(category: CategoriesData) {
  const items = [];

  const channel = category.channel;
  const channelConfig = channels.find((ch) => ch.url === channel.url) || null;

  const channelPath = (channel && channel.path) || rootConfig.main_slug;
  if (channelPath !== null) {
    items.push(channelPath);
  }

  // FIXME: could be channelConfig.external_id int to get rid of this .toString()?
  const parentCategory = category.parentCategory || null;
  if (parentCategory && parentCategory.id && parentCategory.id.toString() !== channelConfig?.external_id) {
    const parentSlug = parentCategory.slug;
    items.push(parentSlug);
  }

  return items.join('/');
}

function buildCategoryDevUrl(category: CategoriesData, channelConfig: Channel) {
  const origin = process.client ? document.location.origin : '';
  const path = generatePath(category);
  return channelConfig.old_type_redirects_dev ? `https://${channelConfig.url}/${category.slug}` : `${origin}/${path}/${category.slug}`;
}

function buildCategoryLiveUrl(category: CategoriesData, channelConfig: Channel) {
  const isTest = typeof window === 'undefined' ? process.env.CHANNEL_CONFIG_ENV === 'test' : /.test.delfi/.test(document.location.hostname);
  const domain = isTest ? channelConfig.domain.replace('.delfi', '.test.delfi') : channelConfig.domain;
  const path = generatePath(category);
  return `https://${domain}/${path}/${category.slug}`;
}

const buildCategoryUrl: BuildCategoryUrl = ({ category, domain, fakeDomainEnabled }) => {
  const { channel } = category;
  const channelPath = (category.channel && category.channel.path) || rootConfig.main_slug;
  const channelConfig = channels.find((ch) => ch.url === channel.url) || null;

  let categoryUrl = '';
  const parentCategory = category.parentCategory || null;
  if (parentCategory && parentCategory.id && parentCategory.id.toString() !== channelConfig?.external_id) {
    const parentSlug = parentCategory?.slug;
    categoryUrl = `/${channelPath}/${parentSlug}/${category.slug}`;
  } else {
    categoryUrl = `/${channelPath}/${category.slug}`;
  }

  const isDev = fakeDomainEnabled;
  const sameChannel = domain ? matchChannel(domain, channel.url) : true;
  if (!sameChannel) {
    if (channelConfig) {
      categoryUrl = isDev ? buildCategoryDevUrl(category, channelConfig) : buildCategoryLiveUrl(category, channelConfig);
    } else {
      throw Error;
    }
  }

  return categoryUrl;
};

export default buildCategoryUrl;
