
import Vue from 'vue';
import { calculateHorizontalAnimationDuration } from '@root/modules/podcast/utils/animations';

interface Props {
  text: string;
  playerType: 'desktop' | 'small' | 'big';
  isPlayerVisible: boolean | null;
}

interface Data {
  isInitialTitleAnimation: boolean;
  isFinalTitleAnimation: boolean;
  timer: ReturnType<typeof setTimeout> | undefined;
  animationDuration: number;
}

interface Methods {
  getAnimationDuration(): Promise<number>;
  clearTimer(): void;
  isAnimated(): boolean;
  startAnimation(): void;
}

export default Vue.extend<Data, Methods, unknown, Props>({
  props: {
    text: {
      type: String,
      required: true,
    },
    playerType: {
      type: String as () => Props['playerType'],
      required: true,
    },
    isPlayerVisible: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isInitialTitleAnimation: false,
      isFinalTitleAnimation: false,
      timer: undefined,
      animationDuration: 0,
    };
  },
  watch: {
    text: {
      async handler() {
        this.clearTimer();
        this.isInitialTitleAnimation = false;
        this.isFinalTitleAnimation = false;
        await this.$nextTick();
        if (this.isAnimated()) {
          this.startAnimation();
        }
      },
      immediate: true,
    },
    isPlayerVisible(newValue) {
      this.clearTimer();
      if (newValue && (this.playerType === 'big' || this.playerType === 'small')) {
        if (this.isAnimated()) {
          this.startAnimation();
        }
      }
    },
  },
  methods: {
    isAnimated() {
      const parentElement = this.$refs.titleContainer as HTMLElement;
      return parentElement.clientWidth < parentElement.scrollWidth && this.playerType !== 'big';
    },
    async startAnimation() {
      const animationDuration = await this.getAnimationDuration();
      this.animationDuration = animationDuration * 10;
      this.isInitialTitleAnimation = true;

      this.timer = setTimeout(
        () => {
          this.animationDuration = (animationDuration + animationDuration / 2) * 10;
          this.isInitialTitleAnimation = false;
          this.isFinalTitleAnimation = true;
        },
        animationDuration * 10 + 2500
      );
    },
    async getAnimationDuration() {
      const parentElement = this.$refs.titleContainer as HTMLElement;
      return calculateHorizontalAnimationDuration(parentElement.scrollWidth, parentElement.clientWidth);
    },
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = undefined;
    },
  },
});
