export default {
  jwPlayerCdnUrl: 'https://g.delfi.lt/components/video/jwplayer-8.36.4/jwplayer.js',
  jwPlayerKey: '9DWw9dVYYxaI81olA/MQakzCZ7KSOexX2zl05F0u0dAts9Ii',
  api: {
    mediaGraphqlApiUrl: 'https://media.api.delfi.lt/media/v1/graphql',
    mediaApiUrl: 'https://images.delfi.lt/media-api-image-cropper/v1',
  },
  ads: {
    vastUrl: 'https://adx.adform.net/adx/?mid=%{vastId}&t=2&mkw=%{mkw}&rnd=%{time}',
    ids: {
      default: {
        pre: [525390, 941176],
        mid: 525392,
        post: 525393,
        overlay: 2024966,
      },
      special: {
        pre: [525390, 525392],
        mid: 525392,
        post: 525393,
        overlay: 2024966,
      },
      stream: {
        pre: [525390, 525392],
        mid: 525392,
        post: 525393,
        overlay: 2024966,
      },
      audio: {
        pre: 1494845,
      },
    },
    bids: {
      bidders: [
        {
          id: 1963255,
          name: 'Adform',
        },
        {
          id: 33774857,
          name: 'AppNexus',
        },
        {
          name: 'Rubicon',
          pubid: 16854,
          siteId: 151768,
          zoneId: 3500454,
        },
        {
          id: 1128001,
          name: 'IndexExchange',
        },
        {
          id: 561093220,
          name: 'OpenX',
          delDomain: 'adnet-d.openx.net',
        },
        {
          formatId: 75930,
          name: 'SmartAdServer',
          networkId: 3361,
          pageId: 2005802,
          siteId: 288333,
        },
        {
          name: 'Sovrn',
          tagid: 1246151,
        },
        {
          id: 6130399,
          name: 'PubMatic',
          pubid: 163522,
        },
      ],
      settings: {
        mediationLayerAdServer: 'jwp',
        consentManagement: {
          gdpr: {
            cmpApi: 'iab',
            rules: [
              {
                purpose: 'basicAds',
                enforcePurpose: true,
                enforceVendor: true,
              },
            ],
            defaultGdprScope: true,
            timeout: 800, // GDPR timeout 0ms
          },
          usp: {
            cmpApi: 'iab',
            timeout: 800, // US Privacy timeout 0ms
          },
        },
        buckets: [
          {
            increment: 0.5,
            min: 1.5,
            max: 10.0,
          },
        ],
      },
      ortbParams: {
        plcmt: 1,
      },
    },
  },
  appUa: /(DelfiEE|DelfiLT|DelfiLV)([a-zA-Z]+)\/(\d+)/gi,
  analytics: {
    ga: {
      uid: 'UA-2428893-4',
      trackerName: 'portalMedia',
    },
    gtag: {
      containerId: 'GTM-WL89FDR',
    },
    cXense: {
      siteId: '1142969099341569069',
      origin: 'lth-delfi',
      persistedQueryId: '60134f11dac530e2cd5c308a42f829d82289a3b8',
    },
    gemius: {
      uid: 'ogJFBDdhmQaC8UCzFs2OkqdD74Fgmo_qvprRh62YHX..y7',
      appUid: 'p4M7KvranLSUCeV7NeLOL8R7.q3WVbsHgXn4PWzKv2r.m7',
      trackerUrl: 'https://see.hit.gemius.pl/gplayer.js',
      playerId: 'jwplayer8',
    },
    eventsMap: {
      // jwPlayer event name: tracker event name
      play: 'media_play',
      pause: 'media_pause',
      idle: 'media_stop',
      complete: 'media_end',
      seek: 'media_seek',
      fullscreen: 'media_fullscreen',
      play10: 'media_progress_10',
      play30: 'media_progress_30',
      secondsPlayed: 'media_seconds_played',
      percentsPlayed: 'media_play_percents',
      // ad events
      adImpression: 'media_adImpression',
      adRequest: 'media_adRequest',
    },
  },
  castAppId: '08AAFF5A',
  player: {
    playbackRates: [0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 2.0],
    logoUrl: 'https://g.delfi.lt/glt/c/delfi-live/logo.png',
  },
};
