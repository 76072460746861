import jwplayerTypes, { JwplayerTimeParam } from '@root/modules/podcast/types/jwPlayer';

import { playerAnalyticsConfig } from '@root/modules/podcast/config/player.config';
import { AnalyticsCurrentlyPlaying, AssetType, CurrentlyPlaying } from '@root/modules/podcast/types/player';
import { getPlayTimeFromStorage, savePlaytimeToStorage, clearLastPlayed } from '@root/modules/podcast/utils/storage';
import debounce from 'lodash/debounce';
import omitBy from 'lodash/omitBy';

const audioTypes: Record<AssetType, { type: string }> = {
  articles: { type: 'TTS' },
  audiobooks: { type: 'AUDIOBOOK' },
  podcasts: { type: 'PODCAST' },
};

export const extendCurrentlyPlaying = (currentlyPlaying: CurrentlyPlaying, player: jwplayerTypes.JWPlayer | null): AnalyticsCurrentlyPlaying | null => {
  if (!player) {
    return null;
  }

  const currentlyPlayingExtended: AnalyticsCurrentlyPlaying = {
    ...currentlyPlaying,
    audioId: String(currentlyPlaying.audioId),
    audioType: audioTypes[currentlyPlaying.assetType].type,
    audioTitle: currentlyPlaying.title,
    muted: !player.getMute() && player.getVolume() > 0 ? 0 : 1,
    audioDuration: player.getDuration(),
    parentTitle: currentlyPlaying.parentTitle || '',
  };
  return currentlyPlayingExtended;
};

class PlayerAnalyticsBase {
  public _player!: jwplayerTypes.JWPlayer;
  private _initialPlay: boolean;
  private readonly _eventMap;
  private _trackProgressEvents: Record<string, number | undefined>;
  public _currentlyPlaying: AnalyticsCurrentlyPlaying | null;
  public _lastTime: number;
  public _playTimeTotal: number;
  public _playTimeFromLastSave: number;

  constructor(player: jwplayerTypes.JWPlayer) {
    this._currentlyPlaying = null;
    this._player = player;
    this._initialPlay = true;
    this._eventMap = playerAnalyticsConfig.eventsMap;
    this._trackProgressEvents = { ...playerAnalyticsConfig.trackProgressEvents };
    this._lastTime = 0;
    this._playTimeFromLastSave = 0;
    this._playTimeTotal = 0;
    this.initAnalytics();
  }

  initAnalytics() {
    this.setEventsListeners();
  }

  getPercent() {
    if (this._player.getPosition()) {
      return Math.floor((this._player.getPosition() / this._player.getDuration()) * 100);
    }
    return 0;
  }

  trackListeningTime(time: JwplayerTimeParam) {
    if (!this._currentlyPlaying) {
      return;
    }
    const playTimeLengthForEvent = 60;
    const saveInterval = 5;
    const { currentTime = 0 } = time;
    const playTime = getPlayTimeFromStorage(this._currentlyPlaying.audioId);

    if (this._lastTime === 0) {
      this._lastTime = currentTime;
      this._playTimeFromLastSave = currentTime;
    }

    this._playTimeTotal += currentTime - this._lastTime;
    this._playTimeFromLastSave += currentTime - this._lastTime;

    if (this._playTimeFromLastSave > saveInterval) {
      this._playTimeFromLastSave = 0;
      savePlaytimeToStorage(this._currentlyPlaying.audioId, this._playTimeTotal, playTime.sent);
    }

    if (this._playTimeTotal >= playTimeLengthForEvent && !playTime.sent) {
      this.sendEvent('audio_listened');
      savePlaytimeToStorage(this._currentlyPlaying.audioId, this._playTimeTotal, true);
    }

    this._lastTime = currentTime;
  }

  protected eventParameters(parameters?: Record<string, string | number>) {
    const baseParameters = {
      audio_id: this._currentlyPlaying?.audioId || '',
      audio_type: this._currentlyPlaying?.audioType || '',
      audio_title: this._currentlyPlaying?.audioTitle || '',
      audio_muted: !this._player.getMute() && this._player.getVolume() > 0 ? 0 : 1,
      audio_position: isNaN(this._currentlyPlaying?.audioPosition || 0) ? 0 : Math.floor(this._currentlyPlaying?.audioPosition || 0),
      audio_time_listened: Math.floor(this._playTimeTotal),
      audio_grouping: this._currentlyPlaying?.parentTitle || '',
    };

    return { ...omitBy(baseParameters, (v) => v === ''), ...parameters };
  }
  setEventsListeners() {
    for (const [jwEvent, eventName] of Object.entries(this._eventMap)) {
      const prepareMethod = (jwEvent: string, eventName: string) => {
        return () => {
          let eventType = eventName;

          if (jwEvent === 'play' && this._initialPlay) {
            eventType = 'audio_start';
            this._initialPlay = false;
          }

          this.sendEvent(eventType);
        };
      };

      const woDebounce = ['audio_stop', 'audio_end'];
      const sendEvent = woDebounce.includes(eventName) ? prepareMethod(jwEvent, eventName) : debounce(prepareMethod(jwEvent, eventName), 500);

      this._player.on(jwEvent as keyof jwplayer.EventParams, sendEvent);
    }

    const secondsFired: number[] = [];

    this._player.on('time', (time) => {
      this.trackListeningTime(time as JwplayerTimeParam);

      const percent = this.getPercent();
      const currentPosition = Math.floor(time.position);

      if (this._currentlyPlaying) {
        this._currentlyPlaying.audioPosition = currentPosition;
      }

      if (this._trackProgressEvents.audio_progress_10 === 1 && percent >= 10) {
        this.sendEvent('audio_progress_10');
        this._trackProgressEvents.audio_progress_10 = undefined;
      } else if (this._trackProgressEvents.audio_progress_30 === 1 && percent >= 30) {
        this.sendEvent('audio_progress_30');
        this._trackProgressEvents.audio_progress_30 = undefined;
      } else if (this._trackProgressEvents.audio_progress_70 === 1 && percent >= 70) {
        this.sendEvent('audio_progress_70');
        this._trackProgressEvents.audio_progress_70 = undefined;
      }

      if (currentPosition > 0 && (currentPosition === 3 || currentPosition % 30 === 0) && !secondsFired.includes(currentPosition)) {
        secondsFired.push(currentPosition);
        this.sendEvent('audio_cons');
      }
    });

    this._player.on('seek', () => {
      this._lastTime = 0;
      this._playTimeFromLastSave = 0;
    });

    // Send event when customer leaves the player page or playlist item completes
    this._player.on('remove', () => this.sendEvent('audio_seconds_played'));
    this._player.on('complete', () => {
      if (this._currentlyPlaying?.audioId && this._currentlyPlaying?.audioPosition) {
        savePlaytimeToStorage(this._currentlyPlaying?.audioId, this._currentlyPlaying?.audioDuration, true);
      }

      this.sendEvent('audio_seconds_played');

      // Clear last played
      clearLastPlayed();
    });

    window.addEventListener('beforeunload', () => this.sendEvent('audio_seconds_played'));
  }

  public setCurrentlyPlaying(currentlyPlaying: CurrentlyPlaying) {
    const currentlyPlayingExtended = extendCurrentlyPlaying(currentlyPlaying, this._player);
    if (currentlyPlayingExtended) {
      this._currentlyPlaying = currentlyPlayingExtended;
      this._playTimeTotal = getPlayTimeFromStorage(this._currentlyPlaying.audioId).playTime;
      this._lastTime = 0;
      this._playTimeFromLastSave = 0;
    }
  }

  public sendEvent(eventName: string) {
    console.log('base class: ', eventName);
  }
}

export default PlayerAnalyticsBase;
