
import { HeadlineTitle, HeadlineImage, HeadlineAuthor, HeadlineLabels, HeadlineIcons, HeadlineLead, HeadlineBookmarks } from '../../../fragments';
import AdFormBanner from '@ads/components/adForm/AdFormBanner.vue';
import BaseBlock from '@headlines/utils/BaseBlock';
import BlockTypeSettings from '@headlines/types/block/1-10/blockType3';

const defaultSettings = {
  icons: true,
  author: true,
  titleComment: true,
  channel: true,
  category: true,
  lead: true,
  type: 'grid',
  adsInject: null,
  indexOffset: 0,
  bookmark: false,
};

export default BaseBlock<BlockTypeSettings>(defaultSettings).extend({
  components: {
    HeadlineImage,
    HeadlineTitle,
    HeadlineAuthor,
    HeadlineLabels,
    HeadlineIcons,
    HeadlineLead,
    HeadlineBookmarks,
    AdFormBanner,
  },
});
