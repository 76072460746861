import { PlaylistType, Podcast } from '@root/modules/podcast/types/podcast';

const storageKey = 'delfi_fone';
const storageClearInterval = 1000 * 60 * 60 * 24 * 31; //31 days

function getNewStorageObj() {
  return { createdAt: Math.floor(Date.now()) };
}

function savePlaytimeToStorage(audioId: string, playTime: number, sent: boolean): void {
  const storageData = localStorage.getItem(storageKey) as string;
  let storedValues: Record<string, any> = {};

  if (storageData) {
    try {
      storedValues = JSON.parse(storageData);
    } catch (error) {
      storedValues = getNewStorageObj();
    }
  } else {
    storedValues = getNewStorageObj();
  }

  // Basic logic for clearing localstorage after X number of days
  if (Date.now() - storedValues.createdAt >= storageClearInterval) {
    storedValues = getNewStorageObj();
  }

  storedValues[audioId] = { playTime, sent, savedAt: Date.now() };

  localStorage.setItem(storageKey, JSON.stringify(storedValues));
}

function getPlayTimeFromStorage(audioId: string) {
  const storageData = localStorage.getItem(storageKey) as string;
  let storedValues: Record<string, any> = {};
  let record = { playTime: 0, sent: false, savedAt: Date.now() };

  if (storageData) {
    try {
      storedValues = JSON.parse(storageData);
      record = storedValues[audioId] || record;
    } catch (error) {
      return record;
    }
  }
  return record;
}

function saveLastPlayed(item: Podcast) {
  localStorage.setItem(`${storageKey}_last`, JSON.stringify(item));
}

function getLastPlayed() {
  const storageData = localStorage.getItem(`${storageKey}_last`) as string;

  return JSON.parse(storageData);
}

function clearLastPlayed() {
  localStorage.removeItem(`${storageKey}_last`);
}

function savePlaylist(playlist: Podcast[], type: PlaylistType = 'podcast') {
  localStorage.setItem(`${storageKey}_playlist_${type}`, JSON.stringify(playlist));
}

function savePlaylistType(type: PlaylistType = 'podcast') {
  localStorage.setItem(`${storageKey}_playlist_type`, type);
}

function getPlaylist(type: PlaylistType = 'podcast') {
  const storageData = localStorage.getItem(`${storageKey}_playlist_${type}`) as string;

  return JSON.parse(storageData);
}

function getPlaylistType() {
  return localStorage.getItem(`${storageKey}_playlist_type`);
}

export {
  savePlaytimeToStorage,
  getPlayTimeFromStorage,
  saveLastPlayed,
  getLastPlayed,
  clearLastPlayed,
  savePlaylist,
  getPlaylist,
  savePlaylistType,
  getPlaylistType,
};
