import jwplayerTypes from '../types/jwPlayer';
import localeStrings from '@root/locales/lt_LT.json';

const playerLocale = localeStrings.player;

const baseSettings = {
  preload: 'auto' as jwplayerTypes.Preload,
  width: '100%',
  height: 1,
  volume: 50,
  mute: false,
  autostart: false,
  playbackRateControls: true,
  intl: { playerLocale },
};

export default baseSettings;
